.bottom-navigation-wrapper{
    display: flex;
}
.bottom-navigation-wrapper svg{
    height: 25px;
    width: 25px;
    stroke: var(--ion-color-primary);
    margin-bottom: 15px;
}
.bottom-navigation-wrapper > div{
    flex: 1;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--ion-color-primary-contrast);

}
.bottom-navigation-wrapper > div.navigation-active{
    background-color: var(--ion-color-primary);
}

.bottom-navigation-wrapper > div.navigation-active *{
    stroke: var(--ion-color-primary-contrast);
    color: var(--ion-color-primary-contrast);

}