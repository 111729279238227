ion-modal.modal-classic.apply-voucher-modal {
	--width: 80%;
	--height: 45%;
	--border-radius: 10px;
}

.web ion-modal.modal-classic.apply-voucher-modal {
	--width: 500px;
	--height: 45%;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-wrapper {
	padding: 0;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header {
	background-color: var(--ion-color-secondary);
	padding-left: 20px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header h3 {
	color: var(--ion-color-white);
	text-transform: uppercase;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-closer {
	color: var(--ion-color-white);
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content {
	padding: 15px 15px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content > .flex-row-wrapper {
	height: 100%;
}

.apply-voucher-modal.voucher-modal-content {
	padding: 0 30px;
}

ion-button.box-button-color {
	--background: var(--ion-color-gray-tint);
}

ion-button.box-button-color-tertiary {
	--background: var(--ion-color-tertiary);
}
/* .apply-voucher {
	border: 5px solid var(--ion-color-primary);
} */
.apply-voucher-box,
.apply-voucher {
	height: 150px;
	margin-right: 2px;
}
.apply-voucher-box img,
.apply-voucher img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}
.web .apply-voucher,
.web .apply-voucher-box {
	cursor: pointer;
	margin: 20px 0 0 !important;
}
.apply-voucher-box .voucher-card-wrapper svg {
	fill: var(--ion-color-secondary);
}
.voucher-svg {
	height: 100%;
	width: 100%;
}

.apply-voucher-box,
.apply-voucher {
	display: flex;
	justify-content: center;
}
.apply-voucher-box .voucher-card-content,
.apply-voucher .voucher-card-content {
	width: auto;
}
.apply-voucher .voucher-card-wrapper {
	border-style: solid;
	border-width: 5px;
	border-color: #1770ff;
	width: 100%;
	height: 100%;
}
.apply-voucher-box .voucher-card-wrapper {
	width: 100%;
	height: 100%;
}
.voucher-icon {
	padding-left: inherit;
}
.voucher-card-content {
	display: -webkit-inline-box;
}
.vaucher-list {
	position: relative;
}

.redeem-voucher-field-wrapper {
	display: flex;
	margin: 0 var(--ion-padding) 30px;
	align-items: flex-end;
	background-color: var(--okx-textfield-background);
	display: flex;
	align-items: center;
	padding: 0 var(--okx-padding) 0 0;
}
.redeem-voucher-field-wrapper > div {
	flex: 1;
	margin-right: 10px;
	margin-bottom: 0;
}
.redeem-voucher-field-wrapper .input-field-container{
	border: 0 !important;
}
.redeem-voucher-field-wrapper ion-button {
	margin: var(--okx-padding) 0;
	height: 40px;
}
.selected-voucher-box {
background-color: var(--ion-color-light);}

.apply-voucher-to-order-btn {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto 70px;
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-color-primary);
	z-index: 5;
}
/* .voucher {
  height: 150px;
  overflow: hidden;
  border-radius: var(--okx-box-radius);
  position: relative;
  margin-top: 15px;
  background-size: cover;
  background-position: center;
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-start !important;
  flex-direction: column;
  cursor: pointer;
} */
:root {
	--voucher-red: #6e2b13;
	--voucher-black: #000;
}

.voucher.green .voucher-content {
	background-color: var(--ion-color-primary);
}

.voucher.red .voucher-content {
	background-color: var(--voucher-red);
}

.voucher.black .voucher-content {
	background-color: var(--voucher-black);
}

.voucher-content {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* .voucher-content::before,
  .voucher-content::after {
    display: block;
    content: '';
    position: absolute;
    top: 8px;
    bottom: 0;
    width: 20px;
    background: radial-gradient(#fff 0px, #fff 6px, transparent 7px, transparent);
    background-size: 20px 20px;
  } */

/* .voucher-content::before {
    left: -9px;
  }
  
  .voucher-content::after {
    right: -9px;
  } */

/* .voucher-content div {
    color: #fff;
  } */

.voucher-spacer {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 20px;
	min-width: 20px;
}

.voucher-info,
.voucher-stamp {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	text-align: center;
}

.voucher-stamp {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 85px;
	min-height: 112px;
	min-width: 85px;
	position: relative;
}

.voucher-stamp::after {
	display: block;
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	width: 70px;
	height: 70px;
	margin: -38px 0 0 -38px;
	border-radius: 50%;
	border: 3px #fff solid;
	background: transparent url(../../assets/images/social-icon.svg) no-repeat 50% 50%;
	background-size: 40px 40px;
}

.voucher-card-wrapper {
	display: flex;
	align-items: center;
}
.voucher-title-wrapper {
	width: 50%;
}
.voucher-title-wrapper .title,
.voucher-title-wrapper .normal-text {
	color: var(--ion-color-primary-contrast);
	text-align: start;
}
.voucher:nth-child(even) .voucher-title-wrapper .title,
.voucher:nth-child(even) .voucher-title-wrapper .normal-text {
	color: var(--ion-color-light-contrast);
}

.voucher-description {
	font-size: 8px;
}
ion-modal .voucher-title-wrapper {
	width: 70%;
}

.voucher-bar {
	display: flex;
	align-items: center;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.voucher-bar > div:first-child,
.voucher-bar > div:last-child {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
}

.voucher-bar > div:first-child {
	margin: 0 10px;
}
.web .route-vouchers .absolute-content {
	padding: var(--ion-padding) 0 60px;
}
.route-vouchers .absolute-content {
	padding-bottom: 60px;
	padding-top: 0;
}
.voucher-bar > div:last-child {
	text-align: right;
	padding-right: 12px;
}

.voucher-bar ion-button {
	margin: 0;
	--border-width: 1px;
	--border-color: rgba(var(--ion-color-gray-rgb), 0.5);
	color: var(--ion-color-secondary);
	font-weight: bold;
	text-transform: uppercase;
	height: 30px !important;
}
.voucher-bar {
	padding: 5px 0;
}
.voucher-bar p {
	font-size: var(--okx-small-text-font-size);
	font-weight: 600;
	text-align: start;
}

ion-modal.modal-classic.voucher-modal {
	--width: 85%;
	--height: 70%;
	--border-radius: 10px;
}
ion-modal.modal-classic.voucher-modal .modal-classic-content {
	padding: 0;
}
ion-modal.modal-classic.voucher-modal .modal-classic-wrapper {
	padding: 0;
}

ion-modal.modal-classic.voucher-modal .modal-classic-header {
	/* background-color: var(--ion-color-secondary);
    padding-left: 20px;
    border-bottom: 2px solid var(--ion-color-tertiary); */
}

ion-modal.modal-classic.voucher-modal .modal-classic-header h3 {
	/* color: var(--ion-color-white);
    font-weight: bold;
    text-transform: uppercase;
  } */
}
ion-modal.modal-classic.voucher-modal .modal-classic-closer {
	position: absolute;
	right: 10px;
	top: 30px;
	padding: 0;
	z-index: 5;
}

.voucher-modal .modal-classic-content .title {
	margin-bottom: 20px;
	text-align: center;
}
.voucher-modal >.modal-wrapper > .ion-page .modal-classic-closer ion-icon {
	height: 25px;
	width: 25px;
	right: 10px !important;
	top: 10px;
}
.voucher-modal-content {
	text-align: start !important;
}

.modal-classic-content > div > img {
	object-fit: cover;
	object-position: center;
}
.voucher svg,
.reward svg {
	height: 100%;
	width: 100%;
	fill: var(--ion-color-primary);
}
.voucher:nth-child(even) svg,
.reward:nth-child(even) svg {
	fill: var(--ion-color-light);
}

.voucher-card-content {
	position: absolute;
	display: flex;
	justify-content: space-between;
	padding: 20px 30px;
	width: 100%;
	align-items: center;
}
.voucher-card-content img {
	height: 100%;
	width: auto;
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content .voucher-modal-content > ion-text > span {
	font-size: 23px;
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content ion-item {
	--border-color: var(--ion-color-light);
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content .inline-input > ion-text {
	flex: 0 0 25%;
}

.voucher-modal-qr {
	background-size: contain;
	width: 100%;
	height: 200px;
}

.voucher-modal svg {
	fill: var(--ion-color-secondary);
}
.voucher-modal .voucher-card-wrapper {
	position: relative;
	margin: 0 30px;
}
.voucher-modal .ion-page {
	overflow: unset;
}
.web .voucher-modal .voucher-card-wrapper ion-text {
	font-size: 12px;
}
.web .voucher-modal .voucher-card-wrapper .title {
	font-size: 20px;
}
.web .static.route-vouchers {
	background: transparent none;
}
.web .voucher {
	margin: 10px 0 0;
	width: 100% !important;
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content {
	border-radius: inherit;
	overflow: hidden;
}
.web #main > ion-content.route-vouchers > .ion-padding {
	background-color: #fff;
}

.web .clickup {
	position: absolute;
}
.voucher-description {
	display: flex;
	justify-content: space-between;
	background-color: var(--ion-color-gray);
	padding: 20px 10px 5px;
	margin-top: -15px;
	border-radius: 0 0 var(--okx-box-radius) var(--okx-box-radius);
}
.voucher-description * {
	color: var(--ion-color-dark);
}
.voucher {
	width: calc(100vw - (var(--ion-padding) * 2));
	/* height: calc(50vw - var(--ion-padding)); */
	height: 150px;
	overflow: hidden;
	border-radius: var(--okx-box-radius);
	position: relative;
	margin-top: 15px;
	background-size: cover;
	background-position: center;
	padding: 5px 10px;
	display: flex;
	justify-content: flex-end !important;
	align-items: flex-start !important;
	flex-direction: column;
	cursor: pointer;
}

.voucher .subtitle {
	color: var(--ion-color-white);
	z-index: 5;
}

.voucher > * {
	z-index: 5;
}
.voucher::before {
	content: '';
	z-index: 0;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 100%;
	z-index: 0;
	background-image: linear-gradient(rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.7) 100%);
}

.voucher-modal >.modal-wrapper > .ion-page {
	min-width: 100%;
	overflow: auto;
	background-color: var(--okx-background-color);
	justify-content: flex-start;
}
.voucher-modal >.modal-wrapper > .ion-page .modal-classic-content {
	padding: 30px var(--okx-padding) var(--okx-padding);
}
.voucher-modal >.modal-wrapper {
	height: 100%;
	width: 100%;
	border-radius: 0 !important;
	overflow: unset;
}

.web .voucher-modal >.modal-wrapper {
	max-width: var(--okx-bsmodal-width-lg);
	width: 100%;
	border-radius: var(--okx-box-radius) !important;

	height: 90%;
	overflow: hidden;
}

.route-apply-loyalty .input-field-container .normal-text {
	font-size: var(--okx-subtitle-font-size);
	font-weight: bold;
}

.web .voucher {
	width: calc(var(--okx-bsmodal-width-lg) - (var(--ion-padding) * 2));
	/* height: calc((var(--okx-bsmodal-width-lg) / 2) - var(--ion-padding)); */
}
.web .route-apply-loyalty .voucher {
	width: calc(var(--okx-bsmodal-width-lg) - (var(--ion-padding) * 2) - 10px);
}

@media screen and (max-width: 1200px) {
	.web .voucher {
		width: calc(var(--okx-bsmodal-width-md) - (var(--ion-padding) * 2));
		/* height: calc((var(--okx-bsmodal-width-md) / 2) - var(--ion-padding)); */
	}
	.web .route-apply-loyalty .voucher {
		width: calc(var(--okx-bsmodal-width-md) - (var(--ion-padding) * 2) - 10px);
	}
	.web .voucher-modal >.modal-wrapper {
		max-width: var(--okx-bsmodal-width-md);
	}
}
@media screen and (max-width: 960px) {
	.web .voucher {
		width: calc(var(--okx-bsmodal-width-sm) - (var(--ion-padding) * 2));
		/* height: calc((var(--okx-bsmodal-width-sm) / 2) - var(--ion-padding)); */
	}
	.web .route-apply-loyalty .voucher {
		width: calc(var(--okx-bsmodal-width-sm) - (var(--ion-padding) * 2) - 10px);
	}
	.web .voucher-modal >.modal-wrapper {
		max-width: var(--okx-bsmodal-width-sm);
	}
}
