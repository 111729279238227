ion-content.route-delivery-options {
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: cover;
}

/* .delivery-options-card ion-card-header {
  margin-bottom: 20px;
} */
.web .ion-padding .delivery-options-card {
	box-shadow: none !important;
	margin: 0 !important;
}
.delivery-options-card {
	position: absolute;
	max-height: 100% !important;
	border-radius: 0 !important;
	background-color: var(--okx-background-color);
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	margin: 0 !important;
	padding: var(--ion-padding) !important;
}

.delivery-options-card ion-card-title {
	font-weight: bold;
	font-family: var(--ion-font-family-bold);
	font-size: var(--okx-subtitle-font-size);
	margin-bottom: 5px;
}

.delivery-options-menu {
	/* padding: 0 40px; */
}

.delivery-options-menu ion-item {
	--color: var(--ion-color-primary-contrast);
	--background: var(--ion-color-primary);
	--min-height: 32px;
	margin-bottom: 16px;
	border-radius: var(--okx-small-radius);
	cursor: pointer;
	text-transform: uppercase;
	font-weight: bold;
}

.delivery-options-menu ion-label {
	text-align: center;
	margin: 10px auto;
}

.web ion-content.route-delivery-options::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	z-index: -1;
}

ion-card-header + .card-content-md {
	padding: 0;
}
.delivery-options-content {
	padding: 0 !important;
}

.route-delivery-options .box-wrapper {
	padding: 0 !important;
}

.route-delivery-options .box-wrapper ion-checkbox {
	margin-left: 10px;
}


.web .delivery-options-card{
	padding-top: var(--okx-padding) !important;
}