.order-content-wrapper ion-row {
  color: var(--ion-color-primary);
}

.order-content-wrapper .basket-item-wrapper + ion-row > ion-col {
  font-weight: normal !important;
}

.order-content-wrapper .basket-item-wrapper + ion-row > ion-col.bold {
  font-weight: bold !important;
}
.order-content-wrapper .basket-item-wrapper .sub-item {
  padding-left: 32px;
  border-top: 0 !important;
}
.order-content-wrapper .basket-item-wrapper:last-of-type {
  border-bottom-width: 0;
}
.order-content-wrapper .basket-item-wrapper-0 ion-row:first-child {
  border-top: 0;
}
.web .order-summary-sidebar .order-content-wrapper .basket-item-wrapper:last-of-type {
  border-bottom-width: 0px;
}
.order-content-wrapper ion-row {
  border-top: 1px solid var(--ion-color-gray);
  padding: var(--okx-padding);
}
.order-content-wrapper ion-row:first-child {
  border-top: none;
}
.order-content-wrapper.box-wrapper ion-row:first-child {
  border-top: none;
}
.subtotal-bordered-top {
  border-top: 2px solid var(--okx-border-color);
  border-bottom: 2px solid var(--okx-border-color);
}

.total-bordered-bottom {
  border-bottom: 2px solid var(--okx-color-black);
}

.total-bordered-top {
  border-top: 2px solid var(--okx-border-color);
  border-bottom: 2px solid var(--okx-border-color);
}

ion-col.grow {
  flex-grow: 3.5;
}

.add-items-btn {
  font-weight: bold;
  margin: 0 var(--okx-padding) !important;
}
.web .order-summary-sidebar .add-items-btn {
  display: none;
}

.service-charge-values {
  display: flex;
}
.service-charge-box-wrapper ion-button {
  flex: 1;
  margin: 0 10px;
  --color: var(--okx-box-wrapper-color);
  --background: transparent !important;
}
.service-charge-box-wrapper ion-button:first-of-type,
.service-charge-box-wrapper ion-button:last-of-type {
  margin: 0;
}

.service-charge-values ion-button {
  --border-width: 0;
}
.service-charge-values ion-button.is-active * {
  color: var(--ion-color-primary-contrast) !important;
}

.service-charge-values ion-button.is-active {
  --ion-color-base: var(--ion-color-primary) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast) !important;
}
.service-charge-values ion-button {
  --ion-color-base: var(--ion-color-gray) !important;
  --ion-color-contrast: var(--ion-color-primary) !important;
}
.service-charge-values ion-button * {
  pointer-events: none !important;
}
.service-charge-box-wrapper {
  width: 100%;
  background-color: var(--okx-box-wrapper-background);
  border-radius: var(--okx-box-radius);
}
.service-charge-box-wrapper .service-charge-box-header {
  padding: var(--okx-padding);
  border-bottom: 1px solid var(--ion-color-gray);
}
.service-charge-box-wrapper > div {
  display: flex;
  justify-content: space-between;
}
.service-charge-box-wrapper .service-charge-values {
  margin: var(--okx-padding) 0 10px;
}
.service-charge-box-modal.modal-wrapper {
  border-radius: 0;
}
.web .service-charge-box-modal.modal-wrapper {
  border-radius: var(--okx-box-radius);
}

.decline-service-charge-btn.link.underlined {
  font-size: calc(var(--ion-font-size) - 2px) !important;
}
.basket-items-wrapper {
  position: relative;
  border-top: 1px solid var(--ion-color-gray);
}
.basket-items-wrapper-gray {
  border-bottom: 5px solid var(--ion-color-gray);
}
.basket-items-wrapper-danger {
  border-bottom: 5px solid var(--ion-color-danger);
}
.basket-items-wrapper-success {
  border-bottom: 5px solid var(--ion-color-success);
}
.basket-items-wrapper-warning {
  border-bottom: 5px solid var(--ion-color-warning);
}
.basket-items-wrapper > div:not(.add-items-button-wrapper) {
  display: flex;
  border-bottom: 1px solid var(--ion-color-gray);
}
.basket-items-wrapper .order-item-image {
  height: 70px;
  width: 70px;
  border-radius: var(--okx-box-radius);
  background-size: contain;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--ion-color-gray);
  position: relative;
}

.basket-items-wrapper .order-item-image::before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.basket-items-wrapper .item-content-wrapper {
  flex: 1;
}
.basket-items-wrapper .item-content-actions {
  margin-top: 20px;
}
.basket-items-wrapper .item-content-actions .incrementer ion-button ion-icon {
  color: var(--ion-color-primary-contrast);
}
.remove-apply-voucher,
.web ion-modal.modal-classic.remove-apply-voucher {
  --height: 200px !important;
}
.remove-apply-voucher .modal-classic-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-summary-items-wrapper {
  padding-right: 100px;
}
ion-modal.modal-classic.remove-apply-voucher .modal-classic-closer {
  top: 25px !important;
  left: unset !important;
  right: 25px !important;
}
.restautant-order-status {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.restautant-order-status .normal-text {
  color: var(--ion-color-white);
}
.restautant-order-status.restautant-order-status-gray .normal-text {
  color: var(--ion-color-primary);
}
.restautant-order-status-success {
  background-color: var(--ion-color-success);
}
.restautant-order-status-danger {
  background-color: var(--ion-color-danger);
}
.restautant-order-status-gray {
  background-color: var(--ion-color-gray);
}
.restautant-order-status-warning {
  background-color: var(--ion-color-warning);
}
