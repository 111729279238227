.history-content {
  text-align: center;
  padding: var(--okx-padding) 0;
  height: 100%;
  background-color: var(--okx-background-color);
}
.history-content-empty{
  padding: var(--okx-padding);

}
.history-content.order-tab .history-item {
  cursor: pointer;
}
.history-content .history-item:not(:last-child) {
  border-bottom: 1px solid var(--ion-color-gray);
}
.history-content .history-item {
  padding: var(--okx-padding);
}
.history-item ion-col{
  padding: 0;
}
.history-item h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}

.history-item ion-col:first-child {
  text-align: left;
}

.history-item ion-col p:first-child {
  color: var(--ion-color-gray);
  font-size: 8px;
  margin-bottom: 8px;
  font-weight: bold;
}

.history-item ion-col p:last-child {
  color: var(--ion-color-medium);
  font-size: 9px;
  margin-top: 2px;
  margin-bottom: 10px;
}

.history-item ion-col:last-child p {
  text-align: right;
  font-size: 14px;
  margin-top: 25px;
  font-weight: 500;
  color: var(--ion-color-dark);
  margin: 0;
}

.history-item ion-col:last-child p::before {
  content: '▲';
  position: relative;
  left: -20px;
  color: var(--ion-color-success);
  opacity: 0.4;
  font-size: 18px;
  display: inline-block;
  transform: scaleY(0.7);
}

.history-item ion-col:last-child p.redeem::before {
  content: '▼';
  color: var(--ion-color-primary);
  opacity: 1;
}

.order-button-wrapper .order-button {
  padding: 2px 4px;
  display: inline-flex;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  border-color: var(--ion-color-black);
}

.order-button-wrapper .order-button ion-icon {
  font-size: 10px;
  margin-left: 8px;
}

.web .order-button-wrapper .order-button {
  cursor: pointer;
}
.no-data-history {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: var(--ion-padding);
  border: 2px dashed var(--okx-box-wrapper-color);
  padding: var(--okx-padding);
  border-radius: 10px;
  opacity: 0.5;
}
.no-data-history img {
  opacity: 0.5;
}

.web .route-history .tabs {
  height: 100%;
  border-radius: inherit;
}

.reorder-button {
  --padding-start: 4px;
  --padding-end: 4px;
}
