.route-apply-loyalty .swiper-slide {
	text-align: left;
}

.route-apply-loyalty .incrementer-quantity-solo {
	flex: 1;
	display: flex;
	flex-direction: column;
}
.route-apply-loyalty .incrementer-quantity-value {
	font-size: 46px;
	color: var(--okx-box-wrapper-color) !important;
	display: flex;
	flex-direction: column;
}
.route-apply-loyalty .incrementer-quantity {
	text-align: center;
}
.route-apply-loyalty .incrementer-decrease,
.route-apply-loyalty .incrementer-incease {
	border-radius: 4px;
	/* --background: var(--okx-box-wrapper-background); */
	--border-width: 1px;
	--border-color: var(--ion-color-primary);
}
.route-apply-loyalty .incrementer-quantity-unit {
	font-family: primary-bold;
}

.route-apply-loyalty .redeem-voucher-field-wrapper {
	margin: var(--ion-padding) 0;
}
