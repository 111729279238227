.subscription-desc-wrapper {
  background-color: var(--ion-color-light);
  padding: 10px;
  text-align: start;
  display: flex;
  flex-direction: column;
}
.subscription-desc-wrapper * {
  align-self: flex-start !important;
}
.subscription-image {
  height: 350px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.web .subscription-image {
  height: 350px;
  width: auto;
}

.subscription-information {
  flex: 1;
  text-align: start !important;
}
.subscription-information * {
  text-align: start !important;
}
.subscription-perks ion-icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.subscription-perks > div {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.subscription-perks > div:not(:last-of-type) {
  margin-bottom: 10px;
}
