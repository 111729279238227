.validate-button .validate-status {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  user-select: none;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.validate-button.valid .validate-status {
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}
.web .validate-button .validate-status {
  padding: 5px;
}
.validate-status u{
  margin-left: 5px;
}
.validate-button {
  padding: 0;
}
.validate-button ion-col {
  padding: 0;
}
