.unlock-reward-wrapper img {
  width: 100%;
  max-height: 110px;
  object-fit: cover;
}
.unlock-reward-wrapper > div:not(.box-wrapper){
    margin: 0 var(--okx-padding);
    margin-bottom: 10px;
}

.unlock-reward-wrapper .box-wrapper > div{
    padding: var(--okx-padding);
}
.unlock-reward-wrapper .box-wrapper > div:not(:first-child){
   border-top: 1px solid var(--ion-color-gray);
}