.incrementer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.route-apply-points .incrementer,
.apply-points-modal .incrementer {
  display: grid;
  grid-template-columns: 35px auto 35px;
  grid-template-areas:
    'decrease points increase'
    'note note note';
}

.incrementer-decrease,
.incrementer-incease {
  flex: 0 0 var(--okx-incrementer-button-width);
  height: var(--okx-incrementer-button-width);
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-gray);
  --background: #fff;
  --background-hover: transparent;
  --color: var(--ion-color-primary);
  margin: 0;
  height: 100%;
}

.route-apply-points .incrementer-decrease,
.apply-points-modal .incrementer-decrease {
  grid-area: decrease;
}

.route-apply-points .incrementer-incease,
.apply-points-modal .incrementer-incease {
  grid-area: increase;
}

.incrementer-quantity {
  flex: 1 1 auto;
  padding-left: var(--okx-incrementer-button-width);
  padding-right: var(--okx-incrementer-button-width);
  font-weight: bold;

}

.incrementer-quantity-solo {
  flex: 1 1 auto;
  text-align: center;
  font-weight: bold;
}

.incrementer-note {
  font-size: var(--okx-small-text-font-size);
  color: var(--okx-color-dark);
  text-align: center;
}

.route-apply-points .incrementer-note,
.apply-points-modal .incrementer-note {
  grid-area: note;
  margin-top: 10px;
}

.route-apply-points .incrementer-quantity-value,
.apply-points-modal .incrementer-quantity-value {
  font-size: 46px;
}

.web ion-modal.modal-classic.quantity-modal .incrementer {
  display: grid;
  grid-template-columns: 35px auto 35px;
  grid-template-rows: 35px;
  margin: auto 0;
}
