.apply-points-spacer {
  margin: 32px 0;
  height: 170px;
}

.route-apply-points .ion-padding .incrementer-quantity-solo {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.route-apply-points .ion-padding .incrementer-quantity-solo .incrementer-quantity-value {
  font-size: var(--okx-big-label-font-size);
}

.route-apply-points .ion-padding .incrementer-decrease,
.route-apply-points .ion-padding .incrementer-incease {
  --border-color: var(--ion-color-secondary);
}

.web .route-apply-points {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web .route-apply-points .ion-padding {
 
}



.web .route-apply-points .ion-padding .absolute-content .scrollable-y/*,
.web .route-apply-points .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.web ion-modal.modal-classic.apply-points-modal .modal-classic-closer {
  font-size: 20px;
  padding: 11px;
}

.web ion-modal.modal-classic.apply-points-modal .incrementer-decrease,
.web ion-modal.modal-classic.apply-points-modal .incrementer-incease {
  --border-color: var(--ion-color-secondary);
}

.apply-points-content{
padding: 10px;
}

.web ion-modal.modal-classic.apply-points-modal .incrementer ion-button,
.route-apply-loyalty .incrementer ion-button {
	height: 35px;
	width: 35px;
	--background: #var(--ion-color-primary);
	--color: var(--ion-color-primary-contrast);
	--border-color: var(--ion-color-primary);
}
.web ion-modal.modal-classic.apply-loyalty-modal .incrementer-quantity,
.route-apply-loyalty .incrementer-quantity {
	padding: 0 10px;
	text-align: center;
}
.apply-loyalty-content.box-content .incrementer ion-button {
	height: 30px;
	width: 30px;
	--border-radius: 5px;
	--background: transparent;
	/* border: 2px solid var(--ion-color-primary); */
}
.apply-loyalty-content.box-content .incrementer ion-button ion-icon {
	color: var(--ion-color-primary-contrast) !important;
}
