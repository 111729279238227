.mbsc-select-input.mbsc-control {
  width: 100%;
  background: transparent;
  color: var(--okx-box-wrapper-color);
  padding: 10px 8px 10px 0;
  border: 0 none;
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
  outline: none;
  text-transform: unset;
}

.mbsc-select-input.mbsc-control::placeholder {
  color: var(--okx-box-wrapper-color);
  opacity: 0.33;
}
.mbsc-mobiscroll .mbsc-sc-itm {
  color: var(--okx-box-wrapper-color);
}
.mbsc-mobiscroll .mbsc-sc-itm.mbsc-sc-itm-sel {
  color: var(--ion-color-primary);
}
.mbsc-ios .mbsc-sc-itm-sel,
.mbsc-ios .mbsc-fr-btn {
  color: var(--okx-box-wrapper-color, #3880ff);
  font-size: 17px;
}

.mbsc-ios .mbsc-sc-itm-sel {
  font-size: 25px;
}

.mbsc-ios .mbsc-sc-whl-o {
  background: transparent !important;
}
.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
.mbsc-ios.mbsc-calendar .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
  background: var(--okx-box-wrapper-background) !important;
}
.select-picker-label {
  display: block;
  font-size: var(--okx-small-text-font-size);
  color: var(--ion-item-color);
  margin-top: 10px;
  margin-left: 2px;
}
.mbsc-fr-c {
  background: var(--okx-box-wrapper-background) !important;
}
.mbsc-sel .mbsc-fr-c,
.mbsc-fr-btn-cont {
  background: var(--okx-box-wrapper-background) !important;
}
.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm span {
  color: var(--okx-box-wrapper-color);
}
.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm.mbsc-sc-itm-sel span {
  color: var(--ion-color-primary);
}

.select-picker-label--primary {
  color: var(--okx-box-wrapper-color);
}

.mbsc-fr-btn-w .mbsc-fr-btn-s {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-cont {
  border: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
}

.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
  color: #a3a3a3;
}

.mbsc-ios .mbsc-sc-itm-sel {
  color: var(--ion-color-primary, #3880ff) !important;
}

.alert-wrapper {
  --background: var(--okx-box-wrapper-background);
}
.alert-wrapper * {
  color: var(--okx-box-wrapper-color) !important;
}
.mbsc-ios.mbsc-fr-inline .mbsc-fr-c {
  border: none;
}
.mbsc-ios.mbsc-fr-inline .mbsc-fr-w{
  border-top: none;
}
