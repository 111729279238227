.toggle {
  display: flex;
  flex-direction: column;
}

.web #main > ion-content.route-register {
  z-index: 5;
}
.route-register .date-picker-wrapper {
  border-bottom: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
  margin-top: 10px;
}
.route-register .date-picker-wrapper input {
  background-color: transparent;
  /* color: var(--ion-color-secon); */
}
.route-register .date-picker-wrapper ion-label {
  font-size: 11px;
  margin-left: 5px;
  opacity: 0.8;
}
.register-terms-wrapper {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: auto 1fr;
}
.route-register ion-checkbox,
.route-contact-details ion-checkbox,
.route-account ion-checkbox {
  margin-right: 20px;
  --border-radius: 2px;
  --border-color: var(--ion-color-primary);
  --border-width: 1px;
  --checkmark-color: var(--ion-color-primary-contrast);
  flex: unset;
}
.route-account ion-checkbox,
.route-register ion-checkbox{
  --border-radius:50%;
  --border-color: var(--ion-color-success);
  
}