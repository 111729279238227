.web .route-cards .ion-padding .absolute-content {
	padding: var(--ion-padding);
}

.web .route-cards .ion-padding ion-button:last-child {
	margin-bottom: 0;
}

.cards-list-card-image {
	/* height: 50px;
	width: 50px;
	background-color: var(--ion-color-white);
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center; */
}
.cards-list-card-image ion-icon {
	height: 30px;
	width: 30px;
	margin-right: 15px;
}

.box-content ion-label {
	margin: 0 !important;
}
.cards-list-card ion-button ion-icon {
	height: 25px;
	width: 25px;
	color: var(--ion-color-danger);
}
.cards-list-card ion-button {
	--background: transparent !important;
}

.cards-list-card {
	margin-bottom: 15px;
}
.no-cards-box {
	background-color: transparent !important;
	border: 2px dashed var(--okx-box-wrapper-color);
	border-radius: var(--okx-box-radius);
	opacity: 0.5;
}
.no-cards-box * {
	color: var(--okx-box-wrapper-color);
}
