ion-button.link.link-centered {
    margin: 0 auto;
  }

  .web .route-checkout-membership {
    --background: transparent none;
    --ion-color-white: transparent;
  }

  .web .route-checkout-membership .ion-padding {

  }



  .web .route-checkout-membership .ion-padding .absolute-content .scrollable-y/*,
  .web .route-checkout-membership .ion-padding .absolute-content .flex-min*/ {
    padding: 0 32px;
  }

  .paymentRequestButton {
    margin-bottom: 20px;
  }

  .paymentRequestButtonCardAdd {
    padding: 0 2px;
  }
  .route-checkout-membership .sectiontitle{
      margin: 5px 0 !important;
  }